#combo-overlay-container {
  position: absolute;
  top: 10%;
  left: 50%;
  transform: translateX(-50%);
  color: white;
  z-index: 99;
  text-align: center;
}

#combo-overlay-text {
  font-family: "Avenir";
  font-size: 6rem;
}

#next-class-img {
  height: 100%;
  width: 100%;
  overflow-x: none;
}

.ClassContainer {
  position: relative;
  z-index: 1;
  overflow: hidden !important;
  height: 100%;
  width: 100%;
  scrollbar-width: none;
}

.Class {
  overflow: hidden;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  object-fit: contain;
  scrollbar-width: none;
}
#next-class-container {
  font-family: "Avenir";
  width: 90%;
  height: 60%;
  position: absolute;
  top: 17%;
  left: 5%;
  text-align: center;
  padding: 5%;
}

#next-class-header {
  font-size: 4.5rem;
  text-transform: uppercase;
}

#next-class-time {
  font-size: 4.5rem;
  margin: 2rem;
  animation: "wiggle" 0.2s linear;
  transition-duration: 0.25s;
  animation-iteration-count: 2;
}

#welcome-to-ilkb-header {
  font-size: 3.5rem;
  text-transform: uppercase;
}

@media screen and (min-width: 900px) {
  #next-class-header {
    font-size: 5.75rem;
  }
  #next-class-time {
    font-size: 5.75rem;
  }
  #welcome-to-ilkb-header {
    font-size: 4.5rem;
  }
}

@media screen and (min-width: 1200px) {
  #next-class-header {
    font-size: 7.25rem;
  }
  #next-class-time {
    font-size: 7.25rem;
  }
  #welcome-to-ilkb-header {
    font-size: 6rem;
  }
}

@media screen and (min-width: 1600px) {
  #next-class-header {
    font-size: 9rem;
  }
  #next-class-time {
    font-size: 9rem;
  }
  #welcome-to-ilkb-header {
    font-size: 7rem;
  }
}

@media screen and (min-width: 1920px) {
  #next-class-header {
    font-size: 12rem;
  }
  #next-class-time {
    font-size: 12rem;
  }
  #welcome-to-ilkb-header {
    font-size: 8.5rem;
  }
}

@keyframes wiggle {
  0% {
    margin-left: 0.8rem;
  }
  25% {
    margin-left: 0rem;
  }
  50% {
    margin-left: -0.8rem;
  }
  75% {
    margin-left: 0rem;
  }
}
