/* 9Round colors */
@import url("https://fonts.googleapis.com/css2?family=Roboto+Mono&display=swap");

/* 9R Font */
@font-face {
  font-family: "Avenir";
  src: url("./fonts/fcb.otf");
}

:root {
  --niner-color: #d32f2f;
}

.text-9r-red {
  color: var(--niner-color);
}

.btn-9r-red {
  background-color: var(--niner-color);
  color: white;
}
.btn-9r-red {
  background-color: var(--niner-color);
  color: white;
}

.btn-9r-red:hover,
.btn-9r-red:focus {
  background-color: #dc5959;
  color: white;
}
nav {
  font-size: 1.25rem;
}

nav h3 {
  font-size: 1rem;
  text-transform: uppercase !important;
}

.navbar .navbar-brand {
  font-size: 1.5rem;
}

.navbar-nav .nav-link {
  font-size: 1.25rem;
}

.App {
  font-family: "Avenir";
}
