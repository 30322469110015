/* Modal Background Overlay */
.modal-overlay-9r {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1020;
  background-color: rgb(0, 0, 0, 255);
  background-repeat: no-repeat;
  background-position-x: 95%;
  background-position-y: 95%;
  background-size: 10%;
  opacity: 0.8;
}

/* Default Bootstrap Overlay */
.modal-overlay-default {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1020;
  background-color: rgb(0, 0, 0, 255);
  background-image: none;
  opacity: 0.7;
}

@media (max-width: 768px) {
  .modal-overlay-9r {
    background-size: 20%;
    background-position-x: 98%;
    background-position-y: 98%;
  }
}

/* Modal Show */
.modal-show-9r {
  position: fixed;
  background: white;
  border: 1px solid black;
  border-radius: 5px;
  z-index: 2000;
  max-height: 600px;
  max-width: 500px;
  margin: auto;
  top: 0;
  right: 0;
  left: 0;
  margin-top: 8.5vh;
}

@media (max-width: 768px) {
  .modal-show-9r .modal-overlay-9r {
    max-height: 90vh;
    max-width: 90vw;
  }
}

/* Modal Children */
.modal-header-9r {
  display: flex;
  align-items: flex-start;
  justify-content: left;
  padding: 1rem 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}

.modal-title-9r {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-weight: 490;
  font-size: 1.6rem;
  text-decoration: none;
  padding: 0px;
}

.modal-body-9r {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
  max-height: calc(100vh - 210px);
  overflow-y: auto;
}

.modal-footer-9r {
  display: flex;
  flex-wrap: wrap;
  align-items: right;
  justify-content: flex-start;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
}

/* Close Button labeled*/
.modal-close-9r {
  position: absolute;
  bottom: 11px;
  right: 12px;
}

/* Close Button unlabeled */
.modal-button-9r {
  background-color: white;
  border: none;
  outline: none;
  position: absolute;
  margin: 7px;
  font-size: 26px;
  color: rgb(126, 126, 126);
  top: 0;
  right: 0;
}

.modal-button-9r:hover {
  color: rgb(56, 56, 56);
  font-weight: bolder;
}

/* Modal & Open Animation */
.modal-show-before-9r {
  animation: openModal linear 0.15s;
}
@keyframes openModal {
  0% {
    opacity: 0.1;
    transform: translateX(-100px);
  }
  25% {
    opacity: 0.3;
    transform: translateX(-75px);
  }
  50% {
    opacity: 0.5;
    transform: translateX(-50px);
  }
  75% {
    opacity: 0.7;
    transform: translateX(-25px);
  }
  100% {
    opacity: 0.9;
    transform: translateX(-5px);
  }
}
@-moz-keyframes openModal {
  0% {
    opacity: 0.1;
    transform: translateX(-100px);
  }
  25% {
    opacity: 0.3;
    transform: translateX(-75px);
  }
  50% {
    opacity: 0.5;
    transform: translateX(-50px);
  }
  75% {
    opacity: 0.7;
    transform: translateX(-25px);
  }
  100% {
    opacity: 0.9;
    transform: translateX(-5px);
  }
}
@-o-keyframes openModal {
  0% {
    opacity: 0.1;
    transform: translateX(-100px);
  }
  25% {
    opacity: 0.3;
    transform: translateX(-75px);
  }
  50% {
    opacity: 0.5;
    transform: translateX(-50px);
  }
  75% {
    opacity: 0.7;
    transform: translateX(-25px);
  }
  100% {
    opacity: 0.9;
    transform: translateX(-5px);
  }
}
@-ms-keyframes openModal {
  0% {
    opacity: 0.1;
    transform: translateX(-100px);
  }
  25% {
    opacity: 0.3;
    transform: translateX(-75px);
  }
  50% {
    opacity: 0.5;
    transform: translateX(-50px);
  }
  75% {
    opacity: 0.7;
    transform: translateX(-25px);
  }
  100% {
    opacity: 0.9;
    transform: translateX(-5px);
  }
}
@-webkit-keyframes openModal {
  0% {
    opacity: 0.1;
    transform: translateX(-100px);
  }
  25% {
    opacity: 0.3;
    transform: translateX(-75px);
  }
  50% {
    opacity: 0.5;
    transform: translateX(-50px);
  }
  75% {
    opacity: 0.7;
    transform: translateX(-25px);
  }
  100% {
    opacity: 0.9;
    transform: translateX(-5px);
  }
}

/* Modal & Close Animation */
.modal-show-after-9r {
  animation: closeModal linear 0.15s;
}
@keyframes closeModal {
  0% {
    opacity: 0.9;
    transform: translateY(-5px);
  }
  25% {
    opacity: 0.7;
    transform: translateY(-25px);
  }
  50% {
    opacity: 0.5;
    transform: translateY(-50px);
  }
  75% {
    opacity: 0.3;
    transform: translateY(-75px);
  }
  100% {
    opacity: 0.1;
    transform: translateY(-100px);
  }
}
@-moz-keyframes closeModal {
  0% {
    opacity: 0.9;
    transform: translateY(-5px);
  }
  25% {
    opacity: 0.7;
    transform: translateY(-25px);
  }
  50% {
    opacity: 0.5;
    transform: translateY(-50px);
  }
  75% {
    opacity: 0.3;
    transform: translateY(-75px);
  }
  100% {
    opacity: 0.1;
    transform: translateY(-100px);
  }
}
@-o-keyframes closeModal {
  0% {
    opacity: 0.9;
    transform: translateY(-5px);
  }
  25% {
    opacity: 0.7;
    transform: translateY(-25px);
  }
  50% {
    opacity: 0.5;
    transform: translateY(-50px);
  }
  75% {
    opacity: 0.3;
    transform: translateY(-75px);
  }
  100% {
    opacity: 0.1;
    transform: translateY(-100px);
  }
}
@-ms-keyframes closeModal {
  0% {
    opacity: 0.9;
    transform: translateY(-5px);
  }
  25% {
    opacity: 0.7;
    transform: translateY(-25px);
  }
  50% {
    opacity: 0.5;
    transform: translateY(-50px);
  }
  75% {
    opacity: 0.3;
    transform: translateY(-75px);
  }
  100% {
    opacity: 0.1;
    transform: translateY(-100px);
  }
}
@-webkit-keyframes closeModal {
  0% {
    opacity: 0.9;
    transform: translateY(-5px);
  }
  25% {
    opacity: 0.7;
    transform: translateY(-25px);
  }
  50% {
    opacity: 0.5;
    transform: translateY(-50px);
  }
  75% {
    opacity: 0.3;
    transform: translateY(-75px);
  }
  100% {
    opacity: 0.1;
    transform: translateY(-100px);
  }
}

/* Modal & Spring Animation*/
.modal-spring-9r {
  animation: springModal linear 0.15s;
}
@keyframes springModal {
  0% {
    transform: translateX(-1px) scale(1.02);
  }
  25% {
    transform: translateX(1px) scale(1.04);
  }
  50% {
    transform: translateX(-2px) scale(1.05);
  }
  75% {
    transform: translateX(2px) scale(1.03);
  }
  100% {
    transform: scale(1);
  }
}
@-moz-keyframes springModal {
  0% {
    transform: translateX(-1px) scale(1.02);
  }
  25% {
    transform: translateX(1px) scale(1.04);
  }
  50% {
    transform: translateX(-2px) scale(1.05);
  }
  75% {
    transform: translateX(2px) scale(1.03);
  }
  100% {
    transform: scale(1);
  }
}
@-ms-keyframes springModal {
  0% {
    transform: translateX(-1px) scale(1.02);
  }
  25% {
    transform: translateX(1px) scale(1.04);
  }
  50% {
    transform: translateX(-2px) scale(1.05);
  }
  75% {
    transform: translateX(2px) scale(1.03);
  }
  100% {
    transform: scale(1);
  }
}
@-o-keyframes springModal {
  0% {
    transform: translateX(-1px) scale(1.02);
  }
  25% {
    transform: translateX(1px) scale(1.04);
  }
  50% {
    transform: translateX(-2px) scale(1.05);
  }
  75% {
    transform: translateX(2px) scale(1.03);
  }
  100% {
    transform: scale(1);
  }
}
@-webkit-keyframes springModal {
  0% {
    transform: translateX(-1px) scale(1.02);
  }
  25% {
    transform: translateX(1px) scale(1.04);
  }
  50% {
    transform: translateX(-2px) scale(1.05);
  }
  75% {
    transform: translateX(2px) scale(1.03);
  }
  100% {
    transform: scale(1);
  }
}
