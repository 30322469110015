/* Alert */
.alert-button {
  transition: transform 0.3s;
  -webkit-transition: transform 0.3s;
  -o-transition: transform 0.3s;
  -moz-transition: transform 0.3s;
  -ms-transition: transform 0.3s;
}

.alert-button:hover {
  transform: scale(1.14);
  -webkit-transform: scale(1.14);
  -o-transform: scale(1.14);
  -moz-transform: scale(1.14);
  -ms-transform: scale(1.14);
}

.alert-button-box {
  color: white;
  opacity: 0.5;
  position: absolute;
  font-family: sans-serif;
  padding: auto;
}

.alert-icon {
  font-size: 20px;
  left: 1;
  top: 2;
}

.alert-icon-message {
  font-family: sans-serif;
  font-size: 15px;
}

.alert-message {
  margin-bottom: 5px;
  margin-top: 5px;
}

.alert-box {
  opacity: 0.9;
  display: grid;
  grid-template-rows: [row1-start] 100% [row1-end row2-start] 100% [row2-end];
  grid-template-columns:
    310px [col-start] calc(100% - 5px) [col-start] calc(100% - 5px)
    [col-start];
}

/* Alert Container */

.alert-container {
  z-index: 999;
  overflow: hidden;
  position: sticky;
  display: absolute;
}

.alert-container-list {
  list-style: none;
  display: flex;
  flex-direction: column-reverse;
  position: fixed;
}

/* Animations */

.alert-animation {
  box-shadow: 1px 2px lightgray;
  border-radius: 2px;
  animation: fadeIn linear 0.2s;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.9;
  }
}
@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.9;
  }
}
@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.9;
  }
}
@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.9;
  }
}
@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.9;
  }
}

.alert-animation-after {
  animation: fadeOut linear 0.2s;
}
@keyframes fadeOut {
  0% {
    opacity: 0.9;
  }
  100% {
    opacity: 0;
  }
}
@-moz-keyframes fadeOut {
  0% {
    opacity: 0.9;
  }
  100% {
    opacity: 0;
  }
}
@-o-keyframes fadeOut {
  0% {
    opacity: 0.9;
  }
  100% {
    opacity: 0;
  }
}
@-ms-keyframes fadeOut {
  0% {
    opacity: 0.9;
  }
  100% {
    opacity: 0;
  }
}
@-webkit-keyframes fadeOut {
  0% {
    opacity: 0.9;
  }
  100% {
    opacity: 0;
  }
}
