.screen-container {
  height: 101%;
  width: 101%;
  overflow: hidden;
}

/* Authentication styles */
.screen-auth-code {
  background-color: #0a072e;
  padding: 10px;
  border: none;
  border-radius: 10px;
  font-size: 3rem;
  letter-spacing: 7px;
  color: whitesmoke;
  font-weight: bold;
  font-family: "Roboto Mono", monospace;
}
